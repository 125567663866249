import { Add } from "@mui/icons-material"
import DeleteIcon from "@mui/icons-material/HighlightOff"
import {
  Avatar,
  Button,
  CardContent,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  List,
  ListItem,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from "@mui/material"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import PropTypes from "prop-types"
import TimePicker from "rc-time-picker"
import { Fragment, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import ActionButton from "../../../ReusableComponents/ActionButton"
import FormDialog from "../../../ReusableComponents/FormDialog"
import Loader from "../../../ReusableComponents/Loader"
import useScrum from "../../../hooks/use-scrums"
import PageLayout from "../../../layouts/PageLayout"
import { getDaysArray } from "../../../utils/utils"
import DialogData from "./DialogData"

const days = ["M", "T", "W", "Th", "F", "S", "Su"]

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

export default function EditScrumPage() {
  const { scrumId } = useParams()
  const [scrum, setScrum] = useState([])
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [time, setTime] = useState("00h00m")
  const [selectedDays, setSelectedDays] = useState([])
  const [channels, setChannels] = useState([])
  const [selectedChannels, setSelectedChannels] = useState([])
  const [users, setUsers] = useState([])
  const navigate = useNavigate()
  const [selectedParticipants, setSelectedParticipants] = useState([])
  const [message, setMessage] = useState("")
  const [questions, setQuestions] = useState([])
  const {
    getScrum,
    getAllChannels,
    getAllUsers,
    updateScrum,
    deleteScrum,
    insertScrum,
  } = useScrum()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      setChannels(await getAllChannels())
      setUsers(await getAllUsers())
      const scrum = await getScrum(scrumId)
      setScrum(scrum)
      setName(scrum?.name || "")
      setDescription(scrum?.description || "")
      setSelectedChannels(scrum?.channels)
      setSelectedParticipants(scrum?.participants ?? [])
      setTime(scrum?.Schedule?.time)
      let selectedDays = []
      scrum?.Schedule?.days?.forEach((day, index) => {
        if (day) {
          selectedDays = [...selectedDays, days[index]]
        }
      })
      setSelectedDays(selectedDays || [])

      let ques = scrum?.questions?.map((question) => question.question)
      setQuestions(ques || [])
      setLoading(false)
    })()
  }, [getAllChannels, getAllUsers, getScrum, scrumId])

  const [show, setShow] = useState(false)
  const closePopUp = () => {
    setShow(false)
  }
  const [showDuplicate, setShowDuplicated] = useState(false)
  const closeDuplicatePopUp = () => {
    setShowDuplicated(false)
  }
  const [showDelete, setShowDelete] = useState(false)
  const closeDeletePopUp = () => {
    setShowDelete(false)
  }
  const [value, setValue] = useState(0)
  const [canPause, setCanPause] = useState(true)

  let created_on = "2023-02-07T12:12:24.913+00:00"
  let creator = "U04QAND58P8"

  function handleName(event) {
    setName(event.target.value)
  }
  function handleDescription(event) {
    setDescription(event.target.value)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  function handleTextField(event) {
    setMessage(event.target.value)
  }
  function addQuestions(event) {
    setQuestions([...questions, message])
    setMessage("")
  }

  function handleQuestions(idx) {
    setQuestions(
      questions.filter((question) => questions.indexOf(question) !== idx),
    )
  }

  const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(" ")

    let [hours, minutes] = time.split(":")

    if (hours === "12") {
      hours = "00"
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12
    }

    return `${hours}h${minutes}m`
  }

  const handleUpdate = () => {
    const days = getDaysArray(selectedDays)
    const creator = "U04QAND58P8"
    const participants = selectedParticipants.map((item) => item._id)
    const created_on = new Date().toISOString()
    updateScrum(
      scrum?._id,
      name,
      description,
      { days, time },
      selectedChannels,
      participants,
      questions,
      creator,
      created_on,
    ).then((res) => {})
  }

  function timeFormat(time) {
    var hours = time.substring(0, 2)
    var minutes = time.substring(3)
    var ftime = `${hours}h${minutes}m`
    return ftime
  }

  const handleDuplicate = () => {
    const participants = selectedParticipants.map((item) => item._id)
    let days = getDaysArray(selectedDays)
    let fftime = timeFormat(time)
    let schedule = { days: days, time: fftime }
    insertScrum(
      name,
      participants,
      description,
      created_on,
      creator,
      questions,
      schedule,
      selectedChannels,
    )

    navigate(`/dashboard`)
  }

  const handleDelete = () => {
    deleteScrum(scrum?._id).then((res) => {})
  }

  return (
    <PageLayout
      title={"Edit "}
      actions={
        <ActionButton
          text="save all"
          onClick={handleUpdate}
        >
          save all
        </ActionButton>
      }
    >
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              inkBarStyle={{ background: "pink" }}
              aria-label="basic tabs example"
            >
              <Tab
                sx={{ textTransform: "capitalize", fontSize: "21px" }}
                label="Basic"
                {...a11yProps(0)}
              />
              <Tab
                sx={{ textTransform: "capitalize", fontSize: "21px" }}
                label="Advanced"
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          <TabPanel
            value={value}
            index={0}
          >
            <Grid
              container
              columnGap="15px"
              flexWrap="nowrap"
            >
              <Grid
                item
                xs={3}
                padding="10px"
                sx={(theme) => ({
                  border: `1.5px solid ${theme.palette.action.selected}`,
                  borderRadius: "12px",
                })}
              >
                <Stack
                  sx={{
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#000000",
                    }}
                  >
                    <label for="standupName">Standup Name</label>
                  </Typography>

                  <TextField
                    InputProps={{ id: "standupName" }}
                    id="name"
                    value={name}
                    onChange={handleName}
                    sx={{
                      backgroundColor: "#fff",
                    }}
                  ></TextField>
                </Stack>
              </Grid>
              <Grid
                item
                xs={9}
                padding="10px"
                sx={(theme) => ({
                  border: `1.5px solid ${theme.palette.action.selected}`,
                  borderRadius: "12px",
                })}
              >
                <Stack
                  sx={{
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#000000",
                    }}
                  >
                    <label for="description">Description</label>
                  </Typography>

                  <TextField
                    inputProps={{ id: "description" }}
                    id="description"
                    value={description}
                    onChange={handleDescription}
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "10px",
                    }}
                  >
                    {scrum?.description}
                  </TextField>
                </Stack>
              </Grid>
            </Grid>
            <Grid
              container
              paddingTop="15px"
              columnGap="15px"
              flexWrap="nowrap"
            >
              <Grid
                item
                xs={6}
              >
                <Grid
                  container
                  columnGap="15px"
                  flexWrap="nowrap"
                >
                  <Grid
                    item
                    xs={6}
                    padding="10px"
                    sx={(theme) => ({
                      border: `1.5px solid ${theme.palette.action.selected}`,
                      borderRadius: "12px",
                    })}
                  >
                    <Stack
                      sx={{
                        borderRadius: "10px",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: "#000000",
                        }}
                      >
                        Schedule
                      </Typography>
                      <Stack direction="row">
                        <Stack
                          width="50%"
                          paddingRight="10px"
                        >
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "14px",
                            }}
                          >
                            Time
                          </Typography>
                          <TimePicker
                            placeholder="Select Time"
                            use12Hours
                            showSecond={false}
                            focusOnOpen={true}
                            format="hh:mm A"
                            onChange={(e) =>
                              setTime(convertTime12to24(e.format("LT")))
                            }
                          />
                        </Stack>
                        <Stack width="50%">
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "14px",
                            }}
                          >
                            Days
                          </Typography>
                          <Select
                            inputProps={{
                              id: "days",
                            }}
                            sx={{
                              width: "100%",
                              height: "28px",
                              backgroundColor: "white",
                            }}
                            size="small"
                            multiple={true}
                            value={[...selectedDays]}
                            onChange={(event) => {
                              setSelectedDays([...event.target.value])
                            }}
                            input={<OutlinedInput label="days" />}
                            renderValue={() => <></>}
                          >
                            {days?.map((day, idx) => (
                              <MenuItem
                                value={day}
                                key={day + idx}
                              >
                                <Checkbox
                                  checked={selectedDays.includes(day)}
                                />
                                <Typography> {day}</Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </Stack>
                      </Stack>
                      <Stack
                        direction="row"
                        paddingTop="5px"
                      >
                        {days.map(
                          (day, idx) =>
                            selectedDays.includes(day) && (
                              <Chip
                                sx={(theme) => ({
                                  fontSize: day.length > 1 ? "10px" : "13px",
                                  margin: "1px",
                                  backgroundColor: "white",
                                  border: `1.5px solid ${theme.palette.action.selected}`,
                                })}
                                label={day}
                                key={day + idx}
                              />
                            ),
                        )}
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={5.7}
                    padding="10px"
                    sx={(theme) => ({
                      border: `1.5px solid ${theme.palette.action.selected}`,
                      borderRadius: "12px",
                    })}
                  >
                    <Stack
                      sx={{
                        borderRadius: "10px",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: "#000000",
                        }}
                      >
                        Braodcast Channels
                      </Typography>

                      <Select
                        inputProps={{
                          id: "channels",
                        }}
                        fullWidth
                        size="small"
                        multiple={true}
                        value={[...selectedChannels]}
                        onChange={(event) => {
                          setSelectedChannels([...event.target.value])
                        }}
                        input={<OutlinedInput />}
                        renderValue={() => <></>}
                      >
                        {channels?.map((channel) => {
                          return (
                            <MenuItem
                              key={channel?.name}
                              value={channel?.name}
                            >
                              <Checkbox
                                checked={selectedChannels?.includes(
                                  channel.name,
                                )}
                              />
                              <Typography>{channel?.name}</Typography>
                            </MenuItem>
                          )
                        })}
                      </Select>
                      <Stack
                        direction={"row"}
                        flexWrap={"wrap"}
                        spacing={1}
                        paddingTop="5px"
                      >
                        {selectedChannels?.map((channel) => (
                          <Chip
                            key={channel}
                            label={channel}
                            sx={(theme) => ({
                              fontSize: "13px",
                              backgroundColor: "#fff",
                              borderRadius: "6px",
                              alignContent: "center",
                              border: `1.5px solid ${theme.palette.action.active}`,
                            })}
                          />
                        ))}
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>

                <Stack paddingTop="15px">
                  <Stack
                    sx={(theme) => ({
                      border: `1.5px solid ${theme.palette.action.selected}`,
                      borderRadius: "12px",
                      height: "223px",
                      padding: "10px",
                    })}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: "#000000",
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: "5px",
                      }}
                    >
                      <label for="questions">Questions</label>
                    </Typography>
                    <Stack
                      direction="row"
                      gap={1}
                    >
                      <TextField
                        value={message}
                        inputProps={{
                          id: "questions",
                        }}
                        onChange={handleTextField}
                        sx={{
                          border: "#fff",
                          width: "80%",
                          fontSize: "14px",
                        }}
                        type="text"
                      />
                      <Button
                        startIcon={<Add />}
                        variant="contained"
                        sx={{ color: "white" }}
                        onClick={addQuestions}
                        disableElevation
                      >
                        add
                      </Button>
                    </Stack>
                    <Stack
                      padding="10px 10px 0 10px"
                      overflow="auto"
                      maxHeight="100%"
                    >
                      <List>
                        {questions?.map((question, idx) => (
                          <Fragment key={question + idx}>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <ListItem>{question}</ListItem>
                              <Button
                                key={idx}
                                onClick={() => {
                                  handleQuestions(idx)
                                }}
                              >
                                <DeleteIcon />
                              </Button>
                            </Stack>
                            <Divider />
                          </Fragment>
                        ))}
                      </List>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
              <Grid
                xs={6}
                item
                sx={(theme) => ({
                  border: `1.5px solid ${theme.palette.action.selected}`,
                  borderRadius: "12px",
                })}
              >
                <Stack
                  padding="10px"
                  sx={{ height: "360px" }}
                >
                  <Typography
                    variant="subtitle2"
                    paddingBottom="5px"
                  >
                    Add Participants
                  </Typography>

                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      size="small"
                      multiple
                      value={selectedParticipants}
                      onChange={(event) => {
                        const {
                          target: { value },
                        } = event
                        let uniqueIDs = [
                          ...new Set(value.map((item) => item._id)),
                        ]
                        let uniqueUsers = users.filter((user) =>
                          uniqueIDs.includes(user._id),
                        )
                        setSelectedParticipants(uniqueUsers)
                      }}
                      input={<OutlinedInput />}
                      renderValue={() => <></>}
                    >
                      {users?.map((user, idx) => (
                        <MenuItem
                          key={user.name + idx}
                          value={user}
                        >
                          <Stack
                            width="100%"
                            direction="row"
                            justifyContent="space-between"
                          >
                            <Stack direction="row">
                              <Avatar src={user.imgUrl} />
                              <Typography
                                display="flex"
                                alignItems="center"
                                paddingLeft="10px"
                              >
                                {user.name}
                              </Typography>
                            </Stack>

                            <Checkbox
                              checked={selectedParticipants
                                .map((item) => item._id)
                                .includes(user._id)}
                            />
                          </Stack>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Stack
                    gap={1}
                    overflow="auto"
                    maxHeight="100%"
                    paddingTop="10px"
                  >
                    {selectedParticipants?.map((participant) => (
                      <Stack
                        direction="row"
                        key={participant._id}
                      >
                        <Avatar src={participant.imgUrl} />
                        <Typography
                          display="flex"
                          alignItems="center"
                          paddingLeft="10px"
                        >
                          {participant.name}
                        </Typography>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel
            value={value}
            index={1}
            sx={{ width: "100%" }}
          >
            <Grid
              container
              spacing={2}
              sx={{ width: "100%" }}
            >
              <Grid
                item
                xs={6}
              >
                <Card>
                  <CardContent
                    sx={{ background: canPause ? "#FDF1D2" : "#EAFFEC" }}
                  >
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "23px",
                        fontWeight: "bold",
                        opacity: "0.8",
                      }}
                    >
                      Pause
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography>
                        Pausing a standup will stop AAPTA from sending DM's to
                        participants. All data will be retained.
                      </Typography>
                      <Button
                        // disableRipple
                        sx={{
                          background: canPause ? "#EBC461" : "#51AE5B",
                          borderRadius: "30px",
                          color: "black",
                          width: "20%",
                          ":hover": {
                            background: canPause ? "#EBC461" : "#51AE5B",
                          },
                        }}
                        onClick={() => {
                          setShow(true)
                        }}
                      >
                        {canPause ? "Pause" : "Resume"}
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <FormDialog
                open={show}
                title=""
                actions={
                  <>
                    <ActionButton
                      text="cancel"
                      onClick={() => {
                        closePopUp()
                      }}
                    />
                    <ActionButton
                      text="confirm"
                      onClick={() => {
                        closePopUp()
                        setCanPause((prev) => !prev)
                      }}
                    />
                  </>
                }
              >
                <DialogData DialogType={canPause ? "pause" : "resume"} />
              </FormDialog>
              <Grid
                item
                xs={6}
              >
                <Card>
                  <CardContent sx={{ background: "#FFE1E1", height: "120px" }}>
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "23px",
                        fontWeight: "bold",
                        opacity: "0.8",
                      }}
                    >
                      Delete
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography>
                        Deleting a standup is permanent and non reversible. All
                        data will be erased!
                      </Typography>
                      <Button
                        onClick={() => setShowDelete(true)}
                        sx={{
                          background: "#CB3939",
                          borderRadius: "30px",
                          color: "black",
                          width: "20%",
                          marginTop: "3px",
                          marginBottom: "3px",
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <FormDialog
                open={showDelete}
                title=""
                actions={
                  <>
                    <ActionButton
                      text="cancel"
                      onClick={() => {
                        closeDeletePopUp()
                      }}
                    />
                    <ActionButton
                      text="confirm"
                      onClick={() => {
                        closeDeletePopUp()
                        handleDelete()
                        navigate(`/dashboard`)
                      }}
                    />
                  </>
                }
              >
                <DialogData DialogType="delete" />
              </FormDialog>
              <Grid
                item
                xs={6}
              >
                <Card>
                  <CardContent sx={{ background: "#D1E6F4" }}>
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "23px",
                        fontWeight: "bold",
                        opacity: "0.8",
                      }}
                    >
                      Duplicate
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography>
                        Duplicating a standup will create a copy with the same
                        settings which can be configured.
                      </Typography>
                      <Button
                        onClick={() => setShowDuplicated(true)}
                        sx={{
                          background: "#6AA1C7",
                          borderRadius: "30px",
                          color: "black",
                          width: "20%",
                        }}
                      >
                        Duplicate
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <FormDialog
                open={showDuplicate}
                title=""
                actions={
                  <>
                    <ActionButton
                      text="cancel"
                      onClick={() => {
                        closeDuplicatePopUp()
                      }}
                    />
                    <ActionButton
                      text="confirm"
                      onClick={() => {
                        closeDuplicatePopUp()
                        handleDuplicate()
                      }}
                    />
                  </>
                }
              >
                <DialogData DialogType="duplicate" />
              </FormDialog>
            </Grid>
          </TabPanel>
        </Box>
      )}
    </PageLayout>
  )
}
