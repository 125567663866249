import { Route, Routes } from "react-router-dom"
import Redirect from "./app/ReusableComponents/Redirect"
import InsightsPage from "./app/pages/InsightsPage"
import NewStandup from "./app/pages/NewStandup/NewStandup"
import PageNotFound from "./app/pages/PageNotFound/PageNotFound"
import RespondentsPage from "./app/pages/RespondentsPage"
import LoginPage from "./app/pages/auth/LoginPage"
import Dashboard from "./app/pages/components/Dashboard"
import ManagePage from "./app/pages/components/ManagePage"
import EditScrumPage from "./app/pages/components/ManagePage/EditScrumPage"
import PrivateRoutes from "./app/pages/auth/PrivateRoutes"

function App() {
  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route
          path="/dashboard"
          element={<Dashboard />}
        />

        <Route
          path="/scrums/:scrumId/insights"
          element={<InsightsPage />}
        />
        <Route
          path="/scrums/:scrumId/insights/respondents"
          element={<RespondentsPage />}
        />
        <Route
          path="/scrums/new"
          element={<NewStandup />}
        />
        <Route
          path="/scrums/:scrumId"
          element={<ManagePage />}
        />
        <Route
          path="/scrums/:scrumId/edit"
          element={<EditScrumPage />}
        />
      </Route>
      <Route
        path="/login"
        element={<LoginPage />}
      />
      <Route
        path="/"
        element={<Redirect to="/login" />}
      />
      <Route
        path="/*"
        element={<PageNotFound />}
      />
    </Routes>
  )
}

export default App
