import SettingsIcon from "@mui/icons-material/Settings"
import { Avatar, AvatarGroup, Box, IconButton, Stack } from "@mui/material"
import Chip from "@mui/material/Chip"
import Typography from "@mui/material/Typography"
import React from "react"
import { Link, useNavigate } from "react-router-dom"
import ToolTip from "../../../../ReusableComponents/ToolTip"
import { getDayLabel } from "../../../../utils/utils"

export default function Scrum({ scrum }) {
  const navigate = useNavigate()

  return (
    <Link
      to={`/scrums/${scrum?._id}/insights`}
      style={{ textDecoration: "none" }}
    >
      <Stack
        justifyContent="space-between"
        sx={{
          backgroundColor: "#002845",
          borderRadius: "35px",
          minHeight: "200px",
          padding: "1.6rem 1.8rem",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
        >
          <Stack>
            <Typography
              color="white"
              variant="h5"
              fontWeight={500}
            >
              {scrum?.name}
            </Typography>
            <Stack
              direction="row"
              spacing={0.8}
              sx={{ paddingTop: "1rem" }}
            >
              {scrum?.Schedule?.days?.map((item, idx) => (
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "16px",
                    width: "16px",
                    borderRadius: "50%",
                    border: `1px solid`,
                    borderColor: item ? "#FF7F33" : "gray",
                    color: item ? "#002845" : "#000",
                    backgroundColor: item ? "white" : "#002845",
                  })}
                >
                  <Typography
                    sx={{ fontSize: "10px", color: item ? "black" : "white" }}
                  >
                    {getDayLabel(idx)}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Stack>
          <ToolTip
            title="manage"
            placement="right"
          >
            <Link to={`/scrums/${scrum?._id}`}>
              <IconButton>
                <SettingsIcon color="common" />
              </IconButton>
            </Link>
          </ToolTip>
        </Stack>
        <Stack
          direction={"row"}
          sx={{ gap: "2px" }}
        >
          {scrum?.channels?.map((channel) => (
            <Chip
              sx={{ backgroundColor: "#fff" }}
              label={channel}
              key={channel}
            />
          ))}
        </Stack>
        <Stack direction={"row"}>
          <AvatarGroup total={scrum?.participants?.length}>
            {scrum?.participants?.map((participant) => (
              <Avatar src={participant.imgUrl} co/>
            ))}
          </AvatarGroup>
        </Stack>
      </Stack>
    </Link>
  )
}
