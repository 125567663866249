import { Avatar, Divider, Grid, Paper, Stack, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import useScrum from "../hooks/use-scrums"
import PageLayout from "../layouts/PageLayout"
import Loader from "../ReusableComponents/Loader"

export default function RespondentsPage() {
  const { scrumId } = useParams()
  const [metrics, setMetrics] = useState([])
  const [users, setUsers] = useState([])
  const { getScrumMetrics, getAllUsers } = useScrum()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      setMetrics(await getScrumMetrics(scrumId))
      setUsers(await getAllUsers())
      setLoading(false)
    })()
  }, [getAllUsers, getScrumMetrics, scrumId])

  const inOfficeUsers =
    users.filter((user) => metrics.inOfficeUsers.some((x) => x === user._id)) ??
    []

  const outOfOfficeUsers =
    users.filter((user) =>
      metrics.outOfOfficeUsers.some((x) => x === user._id),
    ) ?? []
  const respondedUsers =
    users.filter((user) =>
      metrics.respondedUsers.some((x) => x === user._id),
    ) ?? []
  const nonRespondedUsers =
    users.filter((user) =>
      metrics.nonRespondedUsers.some((x) => x === user._id),
    ) ?? []

  return (
    <PageLayout>
      {loading ? (
        <Loader />
      ) : (
        <Grid
          container
          columnGap="10px"
          flexWrap="nowrap"
        >
          <Grid
            item
            xs={3}
            component={Paper}
            variant="elevation"
            sx={(theme) => ({
              border: `1.5px solid ${theme.palette.action.selected}`,
              borderRadius: "12px",
            })}
          >
            <Stack
              p={1}
              spacing={2}
            >
              <Typography
                variant="h6"
                textAlign="center"
              >
                At Work
              </Typography>
              <Divider />
              {inOfficeUsers?.map((user) => (
                <Stack
                  key={user.id}
                  direction="row"
                  spacing={4}
                  alignItems="center"
                  p={1}
                >
                  <Avatar src={user.imgUrl} />
                  <Typography>{user.name}</Typography>
                </Stack>
              ))}
            </Stack>
          </Grid>
          <Grid
            item
            xs={3}
            component={Paper}
            variant="elevation"
            sx={(theme) => ({
              border: `1.5px solid ${theme.palette.action.selected}`,
              borderRadius: "12px",
            })}
          >
            <Stack
              p={1}
              spacing={2}
            >
              <Typography
                variant="h6"
                textAlign="center"
              >
                On Leave
              </Typography>
              <Divider />
              {outOfOfficeUsers?.map((user) => (
                <Stack
                  key={user.id}
                  direction="row"
                  spacing={4}
                  alignItems="center"
                  p={1}
                >
                  <Avatar src={user.imgUrl} />
                  <Typography>{user.name}</Typography>
                </Stack>
              ))}
            </Stack>
          </Grid>
          <Grid
            item
            xs={3}
            component={Paper}
            variant="elevation"
            sx={(theme) => ({
              border: `1.5px solid ${theme.palette.action.selected}`,
              borderRadius: "12px",
            })}
          >
            <Stack
              p={1}
              spacing={2}
            >
              <Typography
                variant="h6"
                textAlign="center"
              >
                Responded
              </Typography>
              <Divider />
              {respondedUsers?.map((user) => (
                <Stack
                  key={user.id}
                  direction="row"
                  spacing={4}
                  alignItems="center"
                  p={1}
                >
                  <Avatar src={user.imgUrl} />
                  <Typography>{user.name}</Typography>
                </Stack>
              ))}
            </Stack>
          </Grid>
          <Grid
            item
            xs={3}
            component={Paper}
            variant="elevation"
            sx={(theme) => ({
              border: `1.5px solid ${theme.palette.action.selected}`,
              borderRadius: "12px",
            })}
          >
            <Stack
              p={1}
              spacing={2}
            >
              <Typography
                variant="h6"
                textAlign="center"
              >
                Not Responded
              </Typography>
              <Divider />
              {nonRespondedUsers?.map((user) => (
                <Stack
                  key={user.id}
                  direction="row"
                  spacing={4}
                  alignItems="center"
                  p={1}
                >
                  <Avatar src={user.imgUrl} />
                  <Typography>{user.name}</Typography>
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>
      )}
    </PageLayout>
  )
}
