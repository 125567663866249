import { Grid, Skeleton } from "@mui/material"
import React from "react"

export default function ScrumSkeleton() {
  return (
    <div
      style={{
        marginLeft: "10px",
        marginTop: "10px",
      }}
    >
      <br />
      <Grid
        container
        gap={"2em"}
      >
        <Skeleton
          sx={{
            bgcolor: "#003c66",
            minHeight: "220px",
            minWidth: "370px",
            borderRadius: "35px",
          }}
          variant="rounded"
        />

        <Skeleton
          sx={{
            bgcolor: "#003c66",
            minHeight: "220px",
            minWidth: "370px",
            borderRadius: "35px",
          }}
          variant="rounded"
        />
        <Skeleton
          sx={{
            bgcolor: "#003c66",
            minHeight: "220px",
            minWidth: "370px",
            borderRadius: "35px",
          }}
          variant="rounded"
        />
        <Skeleton
          sx={{
            bgcolor: "#003c66",
            minHeight: "220px",
            minWidth: "370px",
            borderRadius: "35px",
          }}
          variant="rounded"
        />
        <Skeleton
          sx={{
            bgcolor: "#003c66",
            minHeight: "220px",
            minWidth: "370px",
            borderRadius: "35px",
          }}
          variant="rounded"
        />
        <Skeleton
          sx={{
            bgcolor: "#003c66",
            minHeight: "220px",
            minWidth: "370px",
            borderRadius: "35px",
          }}
          variant="rounded"
        />
        <Skeleton
          sx={{
            bgcolor: "#003c66",
            minHeight: "220px",
            minWidth: "370px",
            borderRadius: "35px",
          }}
          variant="rounded"
        />
        <Skeleton
          sx={{
            bgcolor: "#003c66",
            minHeight: "220px",
            minWidth: "370px",
            borderRadius: "35px",
          }}
          variant="rounded"
        />
        <Skeleton
          sx={{
            bgcolor: "#003c66",
            minHeight: "220px",
            minWidth: "370px",
            borderRadius: "35px",
          }}
          variant="rounded"
        />
      </Grid>
      <br />
    </div>
  )
}
