import { zodResolver } from "@hookform/resolvers/zod"
import { LoadingButton } from "@mui/lab"
import { Avatar, CircularProgress, TextField, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { z } from "zod"
import { API_ROOT } from "../../utils/constants"
import aaptaIcon from "./aapta.png"

export default function LoginPage() {
  const [login, setLogin] = useState(false)
  const [status, setStatus] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const schema = z.object({
    username: z.string().min(1, "This field can't be empty").email(),
    password: z.string().min(1, "This field can't be empty"),
  })
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
    resolver: zodResolver(schema),
    mode: "all",
  })

  const navigate = useNavigate()

  const handleSubmitForm = async (values) => {
    try {
      setIsLoading(true)
      const resp = await fetch(`${API_ROOT}/login`, {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify({
          mail: values.username,
          password: values.password,
        }),
      })
      const { data, message, success } = await resp.json()
      setStatus(message)
      if (success === "true") {
        setLogin(true)
        localStorage.setItem("token", data.token)
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setLogin(false)
      setStatus("Network error. Please try again.")
      setIsLoading(false)
    }
  }

  return (
    <Stack
      justifyContent="center"
      width="100%"
      height="100%"
      alignItems="center"
      marginTop="9%"
    >
      <form
        onSubmit={handleSubmit(handleSubmitForm)}
        style={{
          display: "flex",
          justifyContent: "cente",
          flexDirection: "column",
          border: "2px solid #FF7F33",
          alignItems: "center",
          height: "100%",
          padding: "70px 50px",
          gap: "5px",
          borderRadius: "20px",
          rowGap: "18px",
        }}
      >
        <Avatar
          src={aaptaIcon}
          sx={(theme) => ({
            backgroundColor: theme.palette.secondary.main,
            width: "55px",
            height: "55px",
          })}
        />
        <Typography variant="h6">Admin Login</Typography>
        <Stack>
          <TextField
            sx={{ width: "270px" }}
            size="small"
            variant="outlined"
            type="text"
            placeholder="Username"
            {...register("username")}
          />
          {errors.username && (
            <Typography color="red">{errors.username.message}</Typography>
          )}
        </Stack>

        <Stack>
          <TextField
            type="password"
            size="small"
            sx={{ width: "270px" }}
            variant="outlined"
            placeholder="Password"
            {...register("password")}
          />
          {errors.password && (
            <Typography color="red">{errors.password.message}</Typography>
          )}
        </Stack>

        {login === true ? (
          navigate("/dashboard")
        ) : (
          <Typography color="red">{status}</Typography>
        )}

        <LoadingButton
          loading={isLoading}
          loadingPosition="center"
          loadingIndicator={
            <CircularProgress
              size={20}
              sx={{ color: "white" }}
            />
          }
          type="submit"
          sx={(theme) => ({
            color: "white",
            width: "100px",
            backgroundColor: theme.palette.secondary.main,
            height: "40px",
            borderRadius: "20px",
            textTransform: "capitalize",
            ":hover": {
              backgroundColor: theme.palette.primary.main,
            },
          })}
        >
          Login
        </LoadingButton>
      </form>
    </Stack>
  )
}
