import { Typography } from "@mui/material"
import { Stack } from "@mui/system"
import React from "react"
import WarningIcon from "@mui/icons-material/Warning"
import { Link } from "react-router-dom"

const PageNotFound = () => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      width="100%"
      paddingTop="15%"
    >
      <Stack>
        <Typography
          sx={(theme) => ({
            color: theme.palette.primary.main,
            fontSize: "80px",
          })}
        >
          404
        </Typography>
      </Stack>
      <Stack
        sx={(theme) => ({
          color: theme.palette.secondary.main,
          padding: "10px 20px",
        })}
      >
        <Typography
          sx={{
            fontSize: "25px",
          }}
        >
          <WarningIcon
            sx={(theme) => ({
              color: theme.palette.primary.main,
              marginBottom: "-3px",
            })}
          />
          Oops! Page not found
        </Typography>
        <Typography sx={{ fontSize: "20px" }}>
          We could not find the page you were looking for.
        </Typography>
        <Typography sx={{ fontSize: "17px" }}>
          Meanwhile, you may{" "}
          <Link
            to="/dashboard"
            style={{ textDecoration: "none", color: "#FF7F33" }}
          >
            return to dashboard
          </Link>
        </Typography>
      </Stack>
    </Stack>
  )
}

export default PageNotFound
