import { createTheme } from "@mui/material"

const font = "'Poppins', sans-serif"

export const theme = createTheme({
  typography: {
    fontFamily: font,
  },
  palette: {
    primary: {
      main: "#FF7F33",
    },
    secondary: {
      main: "#002845",
    },
    text: {
      main: "#2B2C34",
    },
    common: {
      main: "#fff",
    },
  },
})
