import { Tooltip, Typography } from "@mui/material"
export default function ToolTip({ title, placement, children }) {
  return (
    <Tooltip
      title={<Typography sx={{ fontSize: "16px" }}>{title}</Typography>}
      color="secondary"
      placement={placement}
      arrow
    >
      {children}
    </Tooltip>
  )
}
