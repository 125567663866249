import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  Grid,
  Input,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import ActionButton from "../../ReusableComponents/ActionButton"
import PageLayout from "../../layouts/PageLayout"
import TimePicker from "rc-time-picker"
import "rc-time-picker/assets/index.css"
import useScrum from "../../hooks/use-scrums"
import { getDaysArray } from "../../utils/utils"
import { useNavigate } from "react-router-dom"
import { Add } from "@mui/icons-material"
import { padding } from "@mui/system"
import Loader from "../../ReusableComponents/Loader"

const days = ["All", "M", "T", "W", "Th", "F", "S", "Su"]

export default function NewStandup() {
  const [time, setTime] = useState("")
  const [selectedChannels, setSelectedChannels] = useState([])
  const [selectedParticipants, setSelectedParticipants] = useState([])
  const [selectedDays, setSelectedDays] = useState([])
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [channels, setChannels] = useState([])
  const [users, setUsers] = useState([])
  const [message, setMessage] = useState("")
  const [questions, setQuestions] = useState([])
  const navigate = useNavigate()
  const { getAllChannels, getAllUsers, insertScrum } = useScrum()
  const [loading, setLoading] = useState(false)

  let created_on = "2023-02-07T12:12:24.913+00:00"
  let creator = "U04QAND58P8"

  const getPariticipantsIds = () => {
    let participants = []
    selectedParticipants?.forEach((participant) => {
      let user = users?.find((user) => user.name === participant)
      if (user) {
        participants.push(user._id)
      }
    })
    return participants
  }

  const selectedParticipantsDetails =
    users.filter((user) => selectedParticipants.some((x) => x === user.name)) ??
    []

  function handleName(event) {
    setName(event.target.value)
  }

  function timeFormat(time) {
    var hours = time.substring(0, 2)
    var minutes = time.substring(3)
    var ftime = `${hours}h${minutes}m`
    return ftime
  }

  function handleTextField(event) {
    setMessage(event.target.value)
  }

  function handleDescription(event) {
    setDescription(event.target.value)
  }

  function addQuestions(event) {
    setQuestions([...questions, message])
    setMessage("")
  }
  useEffect(() => {
    ;(async () => {
      setLoading(true)
      setChannels(await getAllChannels())
      setUsers(await getAllUsers())
      setLoading(false)
    })()
  }, [getAllChannels, getAllUsers, setUsers, setChannels, insertScrum])

  return (
    <PageLayout
      title={"Create New Standup"}
      actions={
        <ActionButton
          text="save all"
          onClick={() => {
            let participants = getPariticipantsIds()
            let days = getDaysArray(selectedDays)
            let fftime = timeFormat(time)
            let schedule = { days: days, time: fftime }
            insertScrum(
              name,
              participants,
              description,
              created_on,
              creator,
              questions,
              schedule,
              selectedChannels,
            )

            navigate(`/dashboard`)
          }}
        >
          save all
        </ActionButton>
      }
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <Grid
            container
            flexWrap="nowrap"
            columnGap="15px"
          >
            <Grid
              item
              xs={3}
              sx={(theme) => ({
                border: `1.5px solid ${theme.palette.action.selected}`,
                borderRadius: "12px",
              })}
            >
              <Stack padding="10px">
                <InputLabel htmlFor="name">
                  <Typography variant="subtitle2">Standup Name</Typography>
                </InputLabel>

                <TextField
                  variant="outlined"
                  id="name"
                  onChange={handleName}
                  type="text"
                />
              </Stack>
            </Grid>
            <Grid
              item
              xs={9}
              sx={(theme) => ({
                border: `1.5px solid ${theme.palette.action.selected}`,
                borderRadius: "12px",
              })}
            >
              <Stack padding="10px">
                <InputLabel htmlFor="description">
                  <Typography variant="subtitle2">Description </Typography>
                </InputLabel>

                <TextField
                  inputProps={{
                    id: "description",
                  }}
                  onChange={handleDescription}
                  type="text"
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            columnGap="15px"
            marginTop="15px"
            flexWrap="noWrap"
          >
            <Grid
              item
              xs={6}
            >
              <Grid
                container
                flexWrap="nowrap"
                columnGap="15px"
              >
                <Grid
                  xs={6}
                  item
                  sx={(theme) => ({
                    border: `1.5px solid ${theme.palette.action.selected}`,
                    borderRadius: "12px",
                  })}
                >
                  <Stack direction="row">
                    <Stack
                      padding="10px"
                      width="50%"
                    >
                      <InputLabel htmlFor="schedule">
                        <Typography variant="subtitle2"> Schedule</Typography>
                      </InputLabel>
                      <TimePicker
                        placeholder="Select Time"
                        use12Hours
                        showSecond={false}
                        focusOnOpen={true}
                        format="hh:mm A"
                        onChange={(e) => setTime(e.format("HH:mm"))}
                      />
                    </Stack>
                    <Stack
                      padding="10px"
                      width="50%"
                    >
                      <InputLabel>
                        <Typography variant="subtitle2">Days</Typography>
                      </InputLabel>

                      <Select
                        inputProps={{
                          id: "days",
                        }}
                        sx={{ width: "100%", height: "28px" }}
                        fullWidth
                        size="small"
                        multiple={true}
                        value={selectedDays}
                        onChange={(event) => {
                          setSelectedDays(
                            typeof event.target.value === "string"
                              ? event.target.value.split(",")
                              : event.target.value,
                          )
                        }}
                        input={<OutlinedInput />}
                        renderValue={() => <></>}
                      >
                        {days?.map((day) => (
                          <MenuItem value={day}>
                            <Checkbox />
                            <Typography> {day}</Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </Stack>
                    </Stack>
                    
                    <Stack
                direction="row"
                paddingTop="5px"
              >
                {selectedDays.map(
                  (day) =>
                    selectedDays.includes(day) && (
                      <Chip
                        sx={(theme) => ({
                          fontSize: day.length > 1 ? "10px" : "13px",
                          margin: "1px",
                          backgroundColor: "white",
                          border: `1.5px solid ${theme.palette.action.selected}`,
                        })}
                        label={day}
                        key={day}
                      />
                    ),
                )}
              </Stack>


                </Grid>
                <Grid
                  xs={5.8}
                  item
                >
                  <Stack
                    padding="10px"
                    sx={(theme) => ({
                      border: `1.5px solid ${theme.palette.action.selected}`,
                      borderRadius: "12px",
                    })}
                  >
                    <InputLabel htmlFor="channels">
                      <Typography variant="subtitle2">
                        Broadcast Channels
                      </Typography>
                    </InputLabel>

                    <Select
                      inputProps={{
                        id: "channels",
                      }}
                      fullWidth
                      size="small"
                      multiple={true}
                      value={selectedChannels}
                      onChange={(event) => {
                        setSelectedChannels(
                          typeof event.target.value === "string"
                            ? event.target.value.split(",")
                            : event.target.value,
                        )
                      }}
                      input={<OutlinedInput />}
                      renderValue={() => <></>}
                    >
                      {channels?.map((channel) => (
                        <MenuItem
                          key={channel.name}
                          value={channel.name}
                        >
                          <Checkbox
                            checked={selectedChannels?.includes(channel.name)}
                          />
                          <Typography> {channel.name}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                    <Stack
                      paddingTop="10px"
                      direction="row"
                      gap={1}
                      flexWrap="wrap"
                    >
                      {selectedChannels?.map((channel) => (
                        <Chip
                          sx={(theme) => ({
                            backgroundColor: "white",
                            border: `1.5px solid ${theme.palette.action.selected}`,
                            borderRadius: "12px",
                          })}
                          label={channel}
                          key={channel}
                        />
                      ))}
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
              <Stack
                marginTop="15px"
                width="96.8%"
              >
                <Stack
                  width="100%"
                  padding="10px"
                  sx={(theme) => ({
                    backgroundColor: "white",
                    border: `1.5px solid ${theme.palette.action.selected}`,
                    borderRadius: "12px",
                  })}
                >
                  <InputLabel htmlFor="questions">
                    <Typography variant="subtitle2">Questions</Typography>
                  </InputLabel>

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Stack width="82%">
                      <TextField
                        fullWidth
                        value={message}
                        inputProps={{
                          id: "questions",
                        }}
                        onChange={handleTextField}
                        type="text"
                      />
                    </Stack>
                    <Button
                      sx={(theme) => ({
                        "&:hover": {
                          backgroundColor: `${theme.palette.primary.dark}`,
                        },
                        backgroundColor: `${theme.palette.primary.main}`,
                        color: "#FFFFFF",
                        paddingInline: "20px",
                      })}
                      startIcon={<Add />}
                      onClick={addQuestions}
                      disableElevation
                    >
                      add
                    </Button>
                  </Stack>
                  <Stack
                    height="220px"
                    maxHeight="220px"
                    overflow="auto"
                  >
                    {" "}
                    <List>
                      {questions?.map((question) => (
                        <>
                          <ListItem>{question}</ListItem>
                          <Divider />
                        </>
                      ))}
                    </List>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
            <Grid
              item
              xs={5.85}
            >
              <Stack
                height="407px"
                padding="10px"
                sx={(theme) => ({
                  border: `1.5px solid ${theme.palette.action.selected}`,
                  borderRadius: "12px",
                })}
              >
                <InputLabel htmlFor="Addparticipants">
                  <Typography
                    variant="subtitle2"
                    paddingBottom="20px"
                  >
                    Add Participants
                  </Typography>
                </InputLabel>
                <FormControl fullWidth>
                  <Select
                    inputProps={{
                      id: "participants",
                    }}
                    fullWidth
                    size="small"
                    multiple={true}
                    value={selectedParticipants}
                    onChange={(event) => {
                      setSelectedParticipants(
                        typeof event.target.value === "string"
                          ? event.target.value.split(",")
                          : event.target.value,
                      )
                    }}
                    input={<OutlinedInput />}
                    renderValue={() => <></>}
                  >
                    {users?.map((user) => (
                      <MenuItem
                        key={user.name}
                        value={user.name}
                      >
                        <Stack
                          direction="row"
                          width="100%"
                          justifyContent="space-between"
                        >
                          <Stack direction="row">
                            <Avatar src={user.imgUrl} />
                            <Typography
                              paddingLeft="10px"
                              display="flex"
                              alignItems="center"
                            >
                              {user.name}
                            </Typography>
                          </Stack>
                          <Checkbox
                            checked={selectedParticipants?.includes(user.name)}
                          />
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Stack
                  maxheight="100%"
                  overflow="auto"
                  gap={2}
                  paddingTop="10px"
                >
                  {selectedParticipantsDetails?.map((participant) => (
                    <>
                      <Stack
                        gap={1}
                        direction="row"
                        key={participant._id}
                      >
                        <Avatar src={participant.imgUrl} />
                        <Typography
                          display="flex"
                          alignItems="center"
                          paddingLeft="10px"
                        >
                          {participant.name}
                        </Typography>
                      </Stack>
                      <Divider />
                    </>
                  ))}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </>
      )}
    </PageLayout>
  )
}
