import DashboardIcon from "@mui/icons-material/DashboardCustomizeOutlined"
import LogoutIcon from "@mui/icons-material/Logout"
import { IconButton, Tooltip } from "@mui/material"
import Drawer from "@mui/material/Drawer"
import { Stack } from "@mui/system"
import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { API_ROOT } from "../../../utils/constants"

const drawerWidth = 80

function SideNav() {
  const navigate = useNavigate()

  const logoutHandler = () => {
    localStorage.removeItem("token")
    navigate("/login")
  }
  const drawer = (
    <Stack
      alignItems="center"
      justifyContent="space-between"
      height="100%"
      sx={(theme) => ({
        paddingBlock: "20px",
        backgroundColor: theme.palette.secondary.main,
      })}
    >
      {/* Primary Nav */}
      <Stack sx={{}}>
        <Tooltip
          title="Dashboard"
          arrow
          placement="right"
        >
          <Link to="/dashboard">
            <IconButton
              sx={(theme) => ({
                ":hover": {
                  backgroundColor: theme.palette.primary.main,
                },
              })}
            >
              <DashboardIcon
                sx={(theme) => ({
                  color: "#fff",
                })}
              />
            </IconButton>
          </Link>
        </Tooltip>
      </Stack>

      {/* Secondary Actions - Logout */}
      <Stack
        gap="10px"
        alignItems="center"
      >
        <Tooltip
          title="Logout"
          placement="right"
          arrow
          color="#fff"
        >
          <IconButton
            onClick={logoutHandler}
            sx={(theme) => ({
              border: `2px solid ${theme.palette.primary.main}`,
            })}
          >
            <LogoutIcon
              sx={{
                color: "#fff",
              }}
            />
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  )

  return (
    <Drawer
      variant="permanent"
      sx={{
        "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
      }}
    >
      {drawer}
    </Drawer>
  )
}
export default SideNav
