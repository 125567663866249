import { useCallback, useContext } from "react"
import { useNavigate } from "react-router-dom"
import axiosClient from "../lib/axiosClient"
import { dateContext } from "../pages/InsightsPage"

function useScrum() {
  const value = useContext(dateContext)

  const getAllScrums = useCallback(async () => {
    const response = await axiosClient.get(`scrums`)
    return response.data.data
  }, [])

  const getScrum = useCallback(async (scrumId) => {
    const response = await axiosClient.get(`scrums/${scrumId}`)
    return response.data.data
  }, [])

  const getScrumMetrics = useCallback(async (scrumId) => {
    const response = await axiosClient.get(`scrums/${scrumId}/metrics`)
    return response.data.data
  }, [])

  const getAllChannels = useCallback(async () => {
    const response = await axiosClient.get(`channels`)
    return response.data.data
  }, [])

  const getAllUsers = useCallback(async () => {
    const response = await axiosClient.get(`users`)
    return response.data.data
  }, [])

  const getResponses = useCallback(async (scrumId, startDate, endDate) => {
    const response = await axiosClient.get(
      `responses/scrum/${scrumId}/${startDate}/${endDate}`,
    )
    return response.data.data
  }, [])

  const navigate = useNavigate()
  const deleteScrum = useCallback(async (scrumId) => {
    const response = await axiosClient.delete(`scrums/${scrumId}`)
    return response.data.data
  }, [])

  const insertScrum = useCallback(
    async (
      name,
      participants,
      description,
      created_on,
      creator,
      questions,
      schedule,
      selectedChannels,
    ) => {
      const response = await axiosClient.post(`scrums`, {
        name,
        participants,
        description,
        created_on,
        creator,
        questions,
        schedule,
        channels: selectedChannels,
      })

      return response.data.data
    },
    [],
  )

  const updateScrum = useCallback(
    async (
      _id,
      name,
      description,
      schedule,
      channels,
      participants,
      questions,
      creator,
      created_on,
    ) => {
      const response = await axiosClient.put(`scrums/${_id}`, {
        _id,
        name,
        description,
        schedule,
        channels,
        participants,
        questions,
        creator,
        created_on,
      })

      if (response.status === 201) {
        navigate(`/scrums/${_id}`)
      }

      return response.data.data
    },
    [],
  )

  return {
    getAllScrums,
    getScrum,
    getAllChannels,
    getAllUsers,
    insertScrum,
    deleteScrum,
    getScrumMetrics,
    getResponses,updateScrum
  }
}

export default useScrum
