import { Stack, TextField, Typography } from "@mui/material";

export default function DialogData({DialogType}){
    switch(DialogType){
        case "delete":
            return (
                <Stack sx={{width:"auto",minWidth:"300px"}}>
                    <Typography sx={{fontSize:"24px"}}>
                        Delete the scrum
                    </Typography>
                    <TextField 
                        autoFocus
                        margin="dense"
                        id="name"
                        label="enter the scrum name"
                        type="email"
                        fullWidth
                        variant="standard">
                    </TextField>
                </Stack>
            )
        case "duplicate":
            return (
                <Stack sx={{width:"auto",minWidth:"300px"}}>
                    <Typography sx={{fontSize:"24px"}}>
                       Enter the new name of the scrum
                    </Typography>
                    <TextField 
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Enter the new name"
                        type="text"
                        fullWidth
                        variant="standard">
                    </TextField>
                </Stack>
            )
        case "resume":
            return ( 
            <Stack sx={{width:"auto",minWidth:"300px"}}>
                <Typography sx={{fontSize:"24px"}}>
                   Confirm Resume
                </Typography>
                <Typography>
                    Are you sure you want to resume the scrum ?
                </Typography>
            </Stack>
            )
        case "pause":
            return ( 
            <Stack sx={{width:"auto",minWidth:"300px"}}>
                <Typography sx={{fontSize:"24px"}}>
                   Confirm Pause
                </Typography>
                <Typography>
                    Are you sure you want to pause the scrum ?
                </Typography>
            </Stack>
            )
        default:
            return(<></>)
    }
}