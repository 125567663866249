import { Box, Stack, Typography } from "@mui/material"
import SideNav from "../pages/components/SideNav/SideNav"

export default function PageLayout({ title, actions, children }) {
  return (
    <>
    <SideNav />
      <Box
        sx={{
          marginLeft: "80px",
          backgroundColor: "#CDE2F5",
          minHeight: "80px",
        }}
      >
        <Box
          sx={{
            maxWidth: "lg",
            marginInline: "auto",
            padding: "22px 0px",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 700,
              }}
            >
              {title}
            </Typography>
            <Stack
              direction="row"
              spacing={2}
            >
              {actions}
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Box
        sx={{
          marginLeft: "100px",
        }}
      >
        <Box
          sx={{
            maxWidth: "lg",
            marginInline: "auto",
            padding: "22px 0px",
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  )
}
