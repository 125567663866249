import {
  Avatar,
  AvatarGroup,
  Box,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import useScrum from "../../../hooks/use-scrums"
import PageLayout from "../../../layouts/PageLayout"
import ActionButton from "../../../ReusableComponents/ActionButton"
import Loader from "../../../ReusableComponents/Loader"
import { getDayLabel } from "../../../utils/utils"

export default function ManagePage() {
  const { scrumId } = useParams()
  const [scrum, setScrum] = useState({})
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { getScrum } = useScrum()

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      setScrum(await getScrum(scrumId))
      setLoading(false)
    })()
  }, [getScrum, scrumId])

  function getTime() {
    let timesch = scrum?.Schedule?.time
    if (timesch) {
      let [hours, minutes, ...rest] = timesch.split(/[hm\s]/)
      let fullTime = `${hours}h:${minutes}m`
      return fullTime
    }
    return ""
  }

  return (
    <PageLayout
      title={scrum?.name}
      actions={
        <ActionButton
          text="Edit"
          onClick={() => navigate(`/scrums/${scrum?._id}/edit`)}
        >
          save all
        </ActionButton>
      }
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <Grid
            container
            flexWrap="nowrap"
            columnGap="15px"
          >
            <Grid
              item
              xs={8}
            >
              <Stack
                padding="10px"
                sx={(theme) => ({
                  border: `1.5px solid ${theme.palette.action.selected}`,
                  borderRadius: "12px",
                })}
              >
                <Typography variant="subtitle2">Description</Typography>
                <Box
                  height="80px"
                  padding="10px"
                  sx={(theme) => ({
                    border: `1.5px solid ${theme.palette.action.active}`,
                    borderRadius: "10px",
                  })}
                >
                  {scrum?.description}
                </Box>
              </Stack>
            </Grid>
            <Grid
              item
              xs={4}
            >
              <Stack
                height="100%"
                justifyContent="center"
                sx={(theme) => ({
                  border: `1.5px solid ${theme.palette.action.selected}`,
                  borderRadius: "10px",
                })}
              >
                <Stack
                  width="100%"
                  alignItems="center"
                >
                  <Typography variant="h3">{getTime()}</Typography>
                </Stack>
                <Stack alignItems="center">
                  <Stack
                    direction="row"
                    spacing={0.8}
                  >
                    {scrum?.Schedule?.days?.map((item, idx) => (
                      <Box
                        sx={(theme) => ({
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "25px",
                          width: "25px",
                          borderRadius: "50%",
                          color: item ? "#002845" : "#000",
                          backgroundColor: item ? "green" : "#D9D9D9",
                          marginLeft: "13px",
                        })}
                      >
                        <Typography
                          sx={{
                            fontSize: "10px",
                            color: item ? "white" : "white",
                          }}
                        >
                          {getDayLabel(idx)}
                        </Typography>
                      </Box>
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            flexWrap="nowrap"
            columnGap="15px"
            paddingTop="15px"
          >
            <Grid
              xs={8}
              item
            >
              <Stack
                padding="10px"
                sx={(theme) => ({
                  border: `1.5px solid ${theme.palette.action.selected}`,
                  borderRadius: "10px",
                })}
              >
                <Typography variant="subtitle2">Questions</Typography>
                <Stack height="223px">
                  <List sx={{ overflow: "auto", maxHeight: "100%" }}>
                    {scrum?.questions?.map((question) => (
                      <>
                        <ListItem>
                          {" "}
                          <Typography sx={{ marginLeft: "5px" }}>
                            {question.question}
                          </Typography>
                        </ListItem>
                        <Divider />
                      </>
                    ))}
                  </List>
                </Stack>
              </Stack>
            </Grid>
            <Grid
              xs={4}
              item
            >
              <Grid
                container
                height="100%"
                rowGap="15px"
              >
                <Grid
                  height="47%"
                  xs={12}
                  sx={(theme) => ({
                    border: `1.5px solid ${theme.palette.action.selected}`,
                    borderRadius: "10px",
                  })}
                >
                  <Stack padding="10px">
                    <Typography variant="subtitle2">Participants</Typography>
                    <Stack
                      direction={"row"}
                      paddingTop="10px"
                    >
                      <AvatarGroup total={scrum?.participants?.length}>
                        {scrum?.participants?.map((participant) => (
                          <Avatar src={participant.imgUrl} />
                        ))}
                      </AvatarGroup>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid
                  height="47%"
                  xs={12}
                  sx={(theme) => ({
                    border: `1.5px solid ${theme.palette.action.selected}`,
                    borderRadius: "10px",
                  })}
                >
                  <Stack padding="10px">
                    <Typography variant="subtitle2">Channels</Typography>
                    <Stack
                      paddingTop="10px"
                      direction="row"
                    >
                      {scrum?.channels?.map((channel) => (
                        <Chip
                          key={channel}
                          label={channel}
                          sx={(theme) => ({
                            border: `1.5px solid ${theme.palette.action.selected}`,
                            borderRadius: "10px",
                            backgroundColor: "#FFFFFF",
                          })}
                        />
                      ))}
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </PageLayout>
  )
}
