import { Button, Typography } from "@mui/material"

export default function ActionButton({
  text = "Submit",
  size = "medium",
  variant = "contained",
  color = "primary",
  onClick = () => {},
  startIcon = null,
  endIcon = null,
  style = {},
  props = {},
}) {
  return (
    <Button
      sx={{
        boxShadow: "none",
        ":hover": {
          boxShadow: "none",
        },
      }}
      variant={variant}
      color={color}
      size={size}
      style={{
        color: "#fff",
        textTransform: "capitalize",
        borderRadius: "8px",
        ...style,
      }}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      {...props}
    >
      <Typography>{text}</Typography>
    </Button>
  )
}
