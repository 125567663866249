export function getDayLabel(index) {
  switch (index) {
    case 0:
      return "M"
    case 1:
      return "T"
    case 2:
      return "W"
    case 3:
      return "T"
    case 4:
      return "F"
    case 5:
      return "S"
    case 6:
      return "S"
    default:
      return "S"
  }
}

export function getDaysArray(selectedDays) {
  let days = [0, 0, 0, 0, 0, 0, 0]
  selectedDays.forEach((day) => {
    if (day === "All") days = [1, 1, 1, 1, 1, 1, 1]
    else if (day === "M") days[0] = 1
    else if (day === "T") days[1] = 1
    else if (day === "W") days[2] = 1
    else if (day === "Th") days[3] = 1
    else if (day === "F") days[4] = 1
    else if (day === "S") days[5] = 1
    else if (day === "Su") days[6] = 1
  })

  return days
}
