import axios from "axios"
import { API_ROOT } from "../utils/constants"

const axiosClient = axios.create({
  baseURL: `${API_ROOT}/`,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
})

export default axiosClient
