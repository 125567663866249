import {
  Timeline,
  TimelineContent,
  TimelineItem,
  TimelineSeparator
} from "@mui/lab"
import { timelineItemClasses } from "@mui/lab/TimelineItem"
import {
  Avatar, Card,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography
} from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import React, { createContext, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import useScrum from "../hooks/use-scrums"
import PageLayout from "../layouts/PageLayout"
import ActionButton from "../ReusableComponents/ActionButton"
import Loader from "../ReusableComponents/Loader"
import { object } from "zod"

import parse, { Node, NodeType } from "slack-message-parser";  //Added for message formatting
const emoji = require("emoji-dictionary");
var parsey = require('html-react-parser');


export const dateContext = createContext("")

function InsightsPage() {
  const { scrumId } = useParams()
  const [scrum, setScrum] = useState([])
  const [startDate, setStartDate] = useState(
    new Date().toISOString().substr(0, 10),
  )
  const { getScrumMetrics } = useScrum()
  const [endDate, setEndDate] = useState(new Date().toISOString().substr(0, 10))
  const [responses, setResponses] = useState([])
  const [metrics, setMetrics] = useState([])
  const { getScrum, getResponses } = useScrum()
  const [loading, setLoading] = useState(false)
  function handleEndDate(event) {
    setEndDate(event.target.value)
  }
  function handleStartDate(event) {
    setStartDate(event.target.value)
  }

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      setScrum(await getScrum(scrumId))
      setMetrics(await getScrumMetrics(scrumId))

      setResponses(await getResponses(scrumId, startDate, endDate))
      setLoading(false)
    })()
  }, [getScrum, scrumId, getResponses, startDate, endDate])

  const inOfficeUsersCount = metrics?.inOfficeUsers?.length
  const outOfOfficeUsersCount = metrics?.outOfOfficeUsers?.length
  const totalUsersCount = inOfficeUsersCount + outOfOfficeUsersCount
  const ratioOfUsers = (inOfficeUsersCount / totalUsersCount) * 100
  const respondedUsersCount = metrics?.respondedUsers?.length
  const nonRespondedUsersCount = metrics?.nonRespondedUsers?.length
  const totalUserss = respondedUsersCount + nonRespondedUsersCount
  const ratioOfRespondents = (respondedUsersCount / totalUserss) * 100
  let names = new Map()
  scrum?.participants?.map((participant) =>
    names.set(participant._id, participant.name),
  )


const stringifyNode = (node: Node) => {
  switch (node.type) {
    case NodeType.Text:
      return node.text;
    case NodeType.Bold:
      return `<b>${node.children.map(stringifyNode).join("")}</b>`;
    case NodeType.Italic:
      return `<i>${node.children.map(stringifyNode).join("")}</i>`;
    case NodeType.Strike:
      return `<s>${node.children.map(stringifyNode).join("")}</s>`;
    case NodeType.Root:
      return node.children.map(stringifyNode).join("");
    case NodeType.UserLink:
      return `<span style="color:#1264A3; background-color:#1D9BD11A; border-radius:3px" >@${names.get(node.userID)}</span>`;
    case NodeType.URL:
      return `<a href="${node.url}">${node.url}</a>`;
    case NodeType.Emoji:
      return emoji.getUnicode(node.name)
    default:
      return node.source;
  }
};

  return (
    <PageLayout
      title={"Standup Insights"}
      actions={<ActionButton text="download">save all</ActionButton>}
    >
      {loading ? (
        <Loader />
      ) : (
        <Grid container>
          <Grid
            item
            xs={4}
          >
            <Stack direction="row">
              <TextField
                id="date"
                label="Start Date"
                type="date"
                defaultValue={new Date().toISOString().substr(0, 10)}
                onChange={handleStartDate}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="date"
                label="End Date"
                type="date"
                defaultValue={new Date().toISOString().substr(0, 10)}
                onChange={handleEndDate}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ marginLeft: "10px" }}
              />
            </Stack>

            <dateContext.Provider
              startDate={startDate}
              endDate={endDate}
            >
              <useScrum />
            </dateContext.Provider>

            <Stack padding="15px 15px 0 0">
              <Card
                sx={(theme) => ({
                  backgroundColor: theme.palette.secondary.main,
                  width: "100%",
                  height: "250px",
                  borderRadius: "10px",
                })}
              >
                <Typography
                  padding="15px"
                  sx={{
                    fontWeight: "bold",
                    color: "#fff",
                  }}
                >
                  Participants
                </Typography>
                <Stack
                  padding="10px"
                  sx={{ overflowY: "auto" }}
                  maxHeight="100%"
                  gap="4px"
                  height="177px"
                >
                  {scrum?.participants?.map((participant) => (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Stack
                        direction="row"
                        gap="5px"
                      >
                        <Stack>
                          <Avatar
                            sx={{ width: 40, height: 40 }}
                            src={participant.imgUrl}
                            key={participant._id}
                          />
                        </Stack>
                        <Stack
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography
                            key={participant._id}
                            sx={{ color: "#fff" }}
                          >
                            @{participant.name}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack>
                        <Checkbox sx={{ color: "#fff" }} />
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              </Card>
            </Stack>
            <Stack padding="15px 15px 0 0">
              <Link
                to={`/scrums/${scrum?._id}/insights/respondents`}
                style={{ textDecoration: "none" }}
              >
                <Card
                  sx={(theme) => ({
                    backgroundColor: theme.palette.secondary.main,
                    height: "250px",
                    width: "100%",
                    borderRadius: "10px",
                  })}
                >
                  <Stack
                    direction="row"
                    padding="15px"
                    alignItems="center"
                  >
                    <Stack position="relative">
                      <CircularProgress
                        variant="determinate"
                        value={ratioOfUsers}
                        size="6rem"
                        sx={{
                          color: "#51AE5B",
                          backgroundColor: "#fff",
                          borderRadius: "50%",
                        }}
                      />
                      <Stack>
                        <Typography
                          position="absolute"
                          top="38%"
                          left="28%"
                          sx={(theme) => ({
                            color: theme.palette.secondary.main,
                            fontSize: "18px",
                          })}
                          variant="subtitle2"
                        >
                          {Math.ceil(ratioOfUsers)}%
                        </Typography>
                      </Stack>{" "}
                    </Stack>
                    <Stack
                      width="100%"
                      paddingLeft="8%"
                    >
                      <Typography
                        sx={{ color: "white" }}
                        variant="h6"
                      >
                        At Work
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Stack
                      direction="row"
                      padding="15px"
                      alignItems="center"
                    >
                      <Stack position="relative">
                        <CircularProgress
                          variant="determinate"
                          value={ratioOfRespondents}
                          size="6rem"
                          sx={{
                            color: "#51AE5B",
                            backgroundColor: "#fff",
                            borderRadius: "50%",
                          }}
                        />
                        <Stack paddingLeft="70%">
                          <Typography
                            position="absolute"
                            top="38%"
                            left="28%"
                            sx={(theme) => ({
                              color: theme.palette.secondary.main,
                              fontSize: "18px",
                            })}
                            variant="subtitle2"
                          >
                            {ratioOfRespondents.toFixed(1)}%
                          </Typography>
                        </Stack>{" "}
                      </Stack>
                      <Stack
                        width="100%"
                        paddingLeft="8%"
                      >
                        <Typography
                          sx={{ color: "white" }}
                          variant="h6"
                        >
                          Responded users
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Card>
              </Link>
            </Stack>
          </Grid>
          <Grid
            item
            xs={8}
            padding="10px"
            sx={(theme) => ({
              border: `1.5px solid ${theme.palette.action.selected}`,
              borderRadius: "10px",
            })}
          >
            <Stack
              width="100%"
              height="510px"
              maxHeight="100%"
              overflow="auto"
            >
              {responses?.map((user) => (
                <Timeline
                  sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      padding: 0,
                    },
                  }}
                  position="right"
                >
                  <TimelineItem>
                    <TimelineSeparator>
                      <Stack
                        direction="row"
                        width="100%"
                      >
                        <Avatar
                          sx={{ width: "45px", height: "45px" }}
                          src={scrum?.participants
                            .filter(
                              (participant) => user.userId === participant._id,
                            )
                            ?.map((participant) =>
                              participant?.imgUrl.toString(),
                            )}
                        />
                        <Stack
                          justifyContent="center"
                          paddingLeft="10px"
                        >
                          <Typography fontWeight="600">
                            {scrum?.participants
                              .filter(
                                (participant) =>
                                  user.userId === participant._id,
                              )
                              ?.map((participant) =>
                                participant?.name.toString(),
                              )}
                          </Typography>
                        </Stack>
                      </Stack>
                      {user?.answers?.map((response) => (
                        <>
                          <TimelineContent width="500px">
                            <TimelineContent />
                            <Stack
                              paddingLeft="10px"
                              sx={(theme) => ({
                                borderLeft: `5px double ${theme.palette.secondary.main}`,
                              })}
                            >
                              <Typography variant="subtitle1" sx={{fontWeight:600}} >
                              {response.question}
                              </Typography>

                              <Typography variant="subtitle1" sx={{ whiteSpace: "pre-line" }}>
                              {parsey(`<div>${stringifyNode(parse(response.answer))}</div>`)}
                              </Typography>
                              
                            </Stack>
                          </TimelineContent>
                        </>
                      ))}
                    </TimelineSeparator>
                  </TimelineItem>
                </Timeline>
              ))}
            </Stack>
          </Grid>
        </Grid>
      )}
    </PageLayout>
  )
}

export default InsightsPage
