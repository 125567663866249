import React, { useState, useEffect } from "react"
import RingLoader from "react-spinners/RingLoader"

export default function Loader() {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    // use fetch  here
    setTimeout(() => {
      setLoading(false)
    }, 10000)
  }, [])
  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <RingLoader
        color={"#FF7F33"}
        loading={loading}
        size={100}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  )
}
