import AddIcon from "@mui/icons-material/Add"
import { Grid } from "@mui/material"
import Scrum from "./Scrum/Scrum"
import PageLayout from "../../../layouts/PageLayout"
import ActionButton from "../../../ReusableComponents/ActionButton"
import { useEffect, useState } from "react"
import useScrum from "../../../hooks/use-scrums"
import { Link, useNavigate } from "react-router-dom"
import ScrumSkeleton from "./Scrum/ScrumSkeleton"

export default function Dashboard() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [scrums, setScrums] = useState([])
  const { getAllScrums } = useScrum()

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      setScrums(await getAllScrums())

      setLoading(false)
    })()
  }, [getAllScrums])

  return (
    <PageLayout
      title={"My Standups"}
      actions={
        <ActionButton
          text="New Standup"
          startIcon={<AddIcon />}
          onClick={() => {
            navigate("/scrums/new")
          }}
        >
          New StandUp
        </ActionButton>
      }
    >
      {loading ? (
        <ScrumSkeleton />
      ) : (
       
          <Grid
            container
            spacing={4}
          >
            {scrums?.map((scrum) => (
              <Grid
                item
                xs={4}
              >
                <Scrum scrum={scrum} />
              </Grid>
            ))}
          </Grid>
      )}
    </PageLayout>
  )
}
